import Header from "components/Header";
import { useFormik } from "formik";
import { useAppContext } from "provider/AppProvider";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import SignatureCanvas from "react-signature-canvas";
import countries from "utils/countries";
import dict from "utils/translations";
import addIcon from "../assets/images/add_circle.svg";
import removeIcon from "../assets/images/delete.svg";

import dayjs from "dayjs";
import styled from "styled-components";
import { useReservationFromQueryParams } from "utils/helper";
import { Text } from "../components/Contents/styles/Contents.styled";
import { useNavigationPath } from "../hooks/useNavigationPath";
import checkinService from "../services/checkin.service";
import theme from "../utils/theme";
import { rausToast } from "../utils/toastify";
import {
  Button,
  ButtonAdd,
  ButtonClear,
  ButtonRemove,
  ButtonWrap,
  ButtonWrapper,
  CheckInWrapper,
  FlexInputField,
  FlexRow,
  FormExplainer,
  FormGroup,
  FormHeadingContainer,
  FormSection,
  FormSubHeading,
  FormWrapper,
  Heading,
  InputField,
  Label,
  LabelWrapper,
  LastSelect,
  Select,
  SignatureContainer,
  SubHeading,
  SubmitDisclaimer,
} from "./styles/CheckIn.styled";
import { pTiny } from "./styles/Typography";

const FieldDescription = styled.div`
  ${pTiny};
  color: ${theme.colors.grayDark};
`;

function CheckIn() {
  const { lang, patchReservationById } = useAppContext();
  const { reservationId, reservation } = useReservationFromQueryParams();

  const [width, setWidth] = useState(0);
  const ref = useRef(null);
  const navigate = useNavigate();
  const buildPath = useNavigationPath();

  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);

  useLayoutEffect(() => {
    setWidth(ref.current.clientWidth);
  }, []);
  const refSign = useRef(null);

  const defaultPersonProperties = {
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    idNumber: "",
    nationality: "",
  };
  const [listPerson, setListPerson] = useState([defaultPersonProperties]);
  const handleChangePerson = (index, values) => {
    setListPerson(
      listPerson.map((v, key) => {
        if (key === index) {
          return { ...v, ...values };
        }
        return v;
      })
    );
  };
  const formik = useFormik({
    initialValues: {
      postcode: "",
      address: "",
      city: "",
      country: "",
      booking_id: reservation?.id || "",
      arrival: reservation?.arrival
        ? dayjs(reservation.arrival).format("YYYY-MM-DD")
        : "",
      departure: reservation?.departure
        ? dayjs(reservation.departure).format("YYYY-MM-DD")
        : "",
    },

    onSubmit: async (values) => {
      console.log("values", JSON.stringify({ ...values, listPerson }, null, 2));

      setIsSubmitEnabled(false);

      try {
        const checkInStatus = await checkinService.saveCheckInForm({
          ...values,
          listPerson,
        });
        await patchReservationById(reservation.id, {
          checkInStatus: true,
        });
        console.log("checkInStatus:", checkInStatus);
        setIsSubmitEnabled(true);
        rausToast.success(dict("checkIn.messsageCheckInSuccessful", lang));
        navigate(buildPath("/reservation", { reservation: reservation.id }));
      } catch (error) {
        setIsSubmitEnabled(true);
        console.error("There was a problem with the fetch operation:", error);
      }
    },
  });

  const allFieldsFilled = () => {
    const requiredFormFields = !!(
      formik.values.address &&
      formik.values.postcode &&
      formik.values.city &&
      formik.values.country &&
      formik.values.signature
    );

    const firstPersonFilled =
      listPerson[0] &&
      listPerson[0].firstName &&
      listPerson[0].lastName &&
      listPerson[0].dateOfBirth &&
      listPerson[0].idNumber &&
      listPerson[0].nationality;

    const result = requiredFormFields && firstPersonFilled;
    return result;
  };

  useEffect(() => {
    const enabled = allFieldsFilled();
    setFormIsValid(enabled);
    setIsSubmitEnabled(enabled);
  }, [formik.values, listPerson]);

  const personalDataFields = [
    {
      label: dict("checkIn.firstName", lang),
      key: "firstName",
      required: true,
      type: "text",
      onChange: handleChangePerson,
    },
    {
      label: dict("checkIn.lastName", lang),
      key: "lastName",
      required: true,
      type: "text",
      onChange: handleChangePerson,
    },
    {
      label: dict("checkIn.dateOfBirth", lang),
      key: "dateOfBirth",
      required: true,
      type: "date",
      onChange: handleChangePerson,
      description: dict("checkIn.dateOfBirthDescription", lang),
      placeholder: "13.09.1980",
    },
    {
      label: dict("checkIn.idNumber", lang),
      key: "idNumber",
      required: true,
      type: "text",
      description: dict("checkIn.idNumberDescription", lang),
      placeholder: "L01X00T47",
      onChange: handleChangePerson,
    },
  ];

  return (
    <>
      <Header />
      <CheckInWrapper className="animate">
        <form onSubmit={formik.handleSubmit}>
          <Heading>{dict("pages.checkIn", lang)}</Heading>

          <FormSection>
            <FormWrapper ref={ref}>
              <SubHeading>{dict("checkIn.personalInfo", lang)}</SubHeading>
              <FormHeadingContainer>
                <FormSubHeading>
                  {dict("checkIn.personHeader", lang)}
                </FormSubHeading>
                <FormExplainer>
                  {dict("checkIn.personExplainer", lang)}
                </FormExplainer>
              </FormHeadingContainer>

              {listPerson.map((v, key) => {
                return (
                  <FormGroup key={key}>
                    {key > 0 && (
                      <div>
                        <ButtonRemove
                          type="button"
                          onClick={() => {
                            setListPerson(
                              listPerson.filter((iv, ikey) => key !== ikey)
                            );
                          }}
                        >
                          <ButtonWrap>
                            <img src={removeIcon} alt="remove" />
                            {dict("checkIn.removeGuest", lang)}
                          </ButtonWrap>
                        </ButtonRemove>
                      </div>
                    )}

                    {personalDataFields.map((field, index) => {
                      return (
                        <React.Fragment
                          key={`personForm${key}-field-${field.key}`}
                        >
                          <LabelWrapper>
                            <Label>
                              {field.label} {key === 0 ? "* " : ""}
                            </Label>
                            {!!field?.description && (
                              <FieldDescription>
                                {field?.description}
                              </FieldDescription>
                            )}
                          </LabelWrapper>
                          <InputField
                            key={`personForm${key}-field-${field.label}`}
                            className="form-control "
                            required={key === 0 && field.required}
                            type="text"
                            placeholder={field.placeholder}
                            onChange={(e) => {
                              field.onChange(key, {
                                ...listPerson[key],
                                [field.key]: e.target.value,
                              });
                            }}
                          />
                        </React.Fragment>
                      );
                    })}
                    <LabelWrapper>
                      <Label>
                        {dict("checkIn.nationality", lang)}{" "}
                        {key === 0 ? "* " : ""}
                      </Label>
                    </LabelWrapper>
                    <Select
                      key={`personForm${key}-field-nationality`}
                      className="form-control"
                      name="nationality"
                      onChange={(e) =>
                        handleChangePerson(key, {
                          ...listPerson[key],
                          nationality: e.target.value,
                        })
                      }
                      required={key === 0}
                    >
                      <option>---</option>
                      {countries.map((country, key) => {
                        return (
                          <option key={key} value={country.code}>
                            {country.name}
                          </option>
                        );
                      })}
                    </Select>
                  </FormGroup>
                );
              })}
            </FormWrapper>
            <ButtonWrapper>
              <ButtonAdd
                type="button"
                onClick={() => {
                  setListPerson((list) => [...list, defaultPersonProperties]);
                }}
              >
                <ButtonWrap>
                  <img src={addIcon} alt="add" />
                  {dict("checkIn.addGuest", lang)}
                </ButtonWrap>
              </ButtonAdd>
            </ButtonWrapper>
          </FormSection>

          <FormWrapper>
            <FormSection>
              <FormHeadingContainer>
                <FormSubHeading>
                  {dict("checkIn.addressHeader", lang)}{" "}
                </FormSubHeading>
                <FormExplainer>
                  {dict("checkIn.addressExplainer", lang)}
                </FormExplainer>
              </FormHeadingContainer>

              <LabelWrapper>
                <Label> {dict("checkIn.address", lang)} * </Label>
              </LabelWrapper>
              <InputField
                className="form-control "
                name="address"
                onChange={formik.handleChange}
                value={formik.values.address}
                required
                type="text"
              />
              <FlexRow>
                <div style={{ width: "50%" }}>
                  <LabelWrapper>
                    <Label>{dict("checkIn.postcode", lang)} *</Label>
                  </LabelWrapper>
                  <FlexInputField
                    className="form-control "
                    name="postcode"
                    onChange={formik.handleChange}
                    value={formik.values.postcode}
                    required
                    type="text"
                  />
                </div>
                <div style={{ width: "50%" }}>
                  <LabelWrapper>
                    <Label>{dict("checkIn.city", lang)} *</Label>
                  </LabelWrapper>
                  <FlexInputField
                    className="form-control "
                    name="city"
                    onChange={formik.handleChange}
                    value={formik.values.city}
                    required
                    type="text"
                  />
                </div>
              </FlexRow>
              <LabelWrapper>
                <Label>{dict("checkIn.country", lang)} * </Label>
              </LabelWrapper>
              <LastSelect
                className="form-control"
                name="country"
                onChange={formik.handleChange}
                value={formik.values.country}
                required
              >
                <option>---</option>
                {countries.map((country, key) => {
                  return (
                    <option key={key} value={country.code}>
                      {country.name}
                    </option>
                  );
                })}
              </LastSelect>
            </FormSection>

            <FormSection>
              <FormHeadingContainer>
                <FormSubHeading>
                  {dict("checkIn.bookingHeader", lang)}{" "}
                </FormSubHeading>
              </FormHeadingContainer>
              <LabelWrapper>
                <Label> {dict("checkIn.bookingReference", lang)} * </Label>
              </LabelWrapper>
              <InputField
                className="form-control "
                disabled
                name="booking_id"
                onChange={formik.handleChange}
                value={formik.values.booking_id}
                type="text"
              />
              <input
                type="hidden"
                name="signature"
                value={formik.values.signature}
                onChange={formik.handleChange}
              />
              <FlexRow>
                <div style={{ width: "50%" }}>
                  {" "}
                  <LabelWrapper>
                    <Label>{dict("checkIn.checkInDate", lang)} *</Label>
                  </LabelWrapper>
                  <FlexInputField
                    className="form-control "
                    required
                    disabled // at the moment these fields don't do anything so it would be confusing to allow editing
                    name="arrival"
                    noMargin={true}
                    onChange={formik.handleChange}
                    value={formik.values.arrival}
                    type="date"
                  />
                </div>
                <div style={{ width: "50%" }}>
                  <LabelWrapper>
                    <Label>{dict("checkIn.checkOutDate", lang)} *</Label>
                  </LabelWrapper>
                  <FlexInputField
                    className="form-control "
                    required
                    disabled // at the moment these fields don't do anything so it would be confusing to allow editing
                    name="departure"
                    noMargin={true}
                    onChange={formik.handleChange}
                    value={formik.values.departure}
                    type="date"
                  />
                </div>
              </FlexRow>
            </FormSection>
            <FormSection>
              <FormHeadingContainer>
                <FormSubHeading>
                  {dict("checkIn.signatureHeader", lang)}
                </FormSubHeading>
                <FormExplainer>
                  {dict("checkIn.signatureExplainer", lang)}
                </FormExplainer>
              </FormHeadingContainer>
              <SignatureContainer>
                {width > 0 && (
                  <SignatureCanvas
                    ref={refSign}
                    required
                    penColor="black"
                    canvasProps={{
                      width: width,
                      height: 200,
                      className: "sigCanvas",
                    }}
                    onEnd={() => {
                      formik.setFieldValue(
                        "signature",
                        refSign.current.toDataURL()
                      ); // Add this line
                    }}
                  />
                )}
              </SignatureContainer>

              <ButtonClear
                type="button"
                onClick={() => {
                  refSign.current.clear();
                  formik.setFieldValue("signature", null);
                }}
              >
                <ButtonWrap>
                  <img src={removeIcon} alt="remove" />
                  {dict("checkIn.clearSig", lang)}
                </ButtonWrap>
              </ButtonClear>
            </FormSection>
          </FormWrapper>
          <FormWrapper>
            <Button variant="green" type="submit" disabled={!isSubmitEnabled}>
              {dict("checkIn.submit", lang)}
            </Button>
            {!formIsValid && (
              <SubmitDisclaimer>
                <Text>
                  {dict("checkIn.fillRequiredFieldsDisclaimer", lang)}
                </Text>
              </SubmitDisclaimer>
            )}
          </FormWrapper>
        </form>
      </CheckInWrapper>
    </>
  );
}

export default CheckIn;
