import check_box from "assets/images/check_box.svg";
import ButtonLink from "atoms/ButtonLink";
import HostInfo from "components/Contents/HostInfo";
import FireWarning from "components/FireWarning";
import LateCheckOutCard from "components/LateCheckOutCard";
import NotifyIssues from "components/NotifyIssues";
import StayLongerCard from "components/StayLongerCard";
import dayjs from "dayjs";
import "dayjs/locale/de";
import { useAppContext } from "provider/AppProvider";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { Item } from "../../atoms/styles/Collapsible.styled";
import { DAYS_OF_THE_WEEK } from "../../utils/constants";
import { now } from "../../utils/today";
import dict from "../../utils/translations";
import {
  BoxWrapper,
  ContentBox,
  DatesBox,
  DatesWrapper,
  H,
  Image,
  InfoBox,
  P,
} from "../Contents/styles/Contents.styled";
import { Warning } from "../MessageBoxes";

import queryString from "query-string";

const KeyCodeP = styled(P)`
  a {
    color: #006434;
  }
`;

function BasicFacts({ reservation }) {
  const { lang, getDaysBeforeArrival, shouldShowHostInfo } = useAppContext();
  const location = useLocation();
  let params = queryString.parse(location.search);
  const intent = params.intent || false;

  const [hideCard, setHideCard] = useState(
    localStorage.getItem("hideStayLongerCard") === "true"
  );

  const cabin = reservation.cabin;
  const property = reservation.property;

  // handle LATE_CHE
  // console.log("reservation?.bookableServices", reservation?.bookableServices);
  const lateCheckOutServiceBookable =
    reservation?.bookableServices?.find(
      (service) => service.code === "LATE_CHE"
    ) || false;
  const lateCheckOutServiceBooked =
    reservation?.bookedServices?.find(
      (service) => service.code === "LATE_CHE"
    ) || false;

  const lateCheckOutService =
    lateCheckOutServiceBookable || lateCheckOutServiceBooked;
  // console.log("lateCheckOutServiceBookable", lateCheckOutServiceBookable);
  // console.log("lateCheckOutServiceBooked", lateCheckOutServiceBooked);
  // console.log("lateCheckOutService", lateCheckOutService);

  const newArrivalDate = dayjs(reservation.arrival);
  const arrivalTime = getArrivalTime({
    arrivalDate: newArrivalDate,
    lang,
    spotCode: cabin?.spot_code,
  });

  let day = newArrivalDate.date();
  let month = newArrivalDate.month() + 1;
  if (String(day).length === 1) day = "0" + day;
  if (String(month).length === 1) month = "0" + month;
  let arrivalWeekDay = newArrivalDate.locale(lang).format("dddd");

  let newDepartureDate = dayjs(reservation.departure);
  if (lateCheckOutServiceBooked) {
    newDepartureDate = dayjs(reservation.departure).hour(18);
  }

  const departureTime = getDepartureTime({
    departureDate: newDepartureDate,
    lang,
    spotCode: cabin?.spot_code,
  });

  let departureWeekDay = newDepartureDate.locale(lang).format("dddd");

  const dateArrival =
    arrivalWeekDay + ", " + day + "." + month + "." + newArrivalDate.year();

  day = newDepartureDate.date();
  month = newDepartureDate.month();
  month = month + 1;
  if (String(day).length === 1) day = "0" + day;
  if (String(month).length === 1) month = "0" + month;

  const dateDeparture =
    departureWeekDay + ", " + day + "." + month + "." + newDepartureDate.year();

  const daysBeforeArrival = getDaysBeforeArrival(reservation.arrival);

  // console.log("reservation?.upsellStatus", reservation?.upsellStatus);
  // const stayLongerStatus = reservation?.upsellStatus?.status;
  const hasUpsellPotentialAfter = reservation?.upsellStatus?.possibleAfter;
  /*  const hasUpsellPotentialBefore =
    daysBeforeArrival > 0 && reservation?.upsellStatus
  const hasUpsellPotential =
    hasUpsellPotentialAfter || hasUpsellPotentialBefore;*/

  // console.log("IN BASICFACTS -------------");
  // console.log(" > reservation?.upsellStatus", reservation?.upsellStatus);
  // console.log(" > hasUpsellPotentialAfter", hasUpsellPotentialAfter);
  // console.log(" > hasUpsellPotentialBefore", hasUpsellPotentialBefore);
  // console.log(" > hasUpsellPotential", hasUpsellPotential);
  // console.log(" > lang", lang);
  // console.log(" > lateCheckOutServiceBookable", !!lateCheckOutServiceBookable);
  // console.log(" > lateCheckOutServiceBooked", !!lateCheckOutServiceBooked);
  // console.log(" > daysBeforeArrival", daysBeforeArrival);

  useEffect(() => {
    if (
      daysBeforeArrival <= 7 &&
      localStorage.getItem("hideStayLongerCard") === "true"
    ) {
      setHideCard(false);
    } else {
      setHideCard(false);
    }
  }, [daysBeforeArrival]);

  const getKeyCodeLabel = () => {
    // Even if the user hasn't checked-in yet, latest 2 days before arrival the code should be accessible
    // We want to prevent that people can't open the cabin door because they haven't checked-in yet
    if (!reservation.checkInStatus && daysBeforeArrival > 2)
      return dict("contents.tbaNotCheckedIn", lang);

    if (daysBeforeArrival > 2) return dict("contents.tba", lang);
    const hasAnyKeyCode = reservation?.keyCode || cabin?.nuki_fallback_code;
    if (!hasAnyKeyCode) return dict("contents.codeError", lang);
    return reservation?.hubSpotDeal?.nuki_keycode || cabin?.nuki_fallback_code;
  };

  // handle additional codes for booked extras
  const AD_BIK_LS_Booked =
    reservation.bookedServices?.find(
      (service) => service.code === "AD_BIK_LS"
    ) || false;
  const AD_EBIK_LS_Booked =
    reservation.bookedServices?.find(
      (service) => service.code === "AD_EBIK_LS"
    ) || false;
  const AD_BIK_ZW_Booked =
    reservation.bookedServices?.find(
      (service) => service.code === "AD_BIK_ZW"
    ) || false;
  const AD_BB_LS_Booked =
    reservation.bookedServices?.find(
      (service) => service.code === "AD_BB_LS" || service.code === "AD_BBQ"
    ) || false;

  const showHostInfo = shouldShowHostInfo(reservation);

  return (
    <ContentBox>
      <Item>
        <InfoBox>
          {cabin?.overview_image?.image_mobile?.data?.attributes?.formats
            ?.medium?.url ? (
            <Image
              src={
                cabin.overview_image.image_mobile.data.attributes.formats.medium
                  .url
              }
              alt="Cabin"
            />
          ) : (
            <Image src="/images/haus.svg" alt="Cabin" />
          )}
        </InfoBox>
        <BoxWrapper>
          <DatesWrapper>
            <DatesBox>
              <H>
                {!cabin
                  ? dict("contents.locationTitle", lang)
                  : dict("contents.cabinTitle", lang)}
              </H>
              <P>
                {!cabin || !cabin.name || cabin.name === property.name
                  ? property.name
                  : cabin.name}
              </P>
            </DatesBox>
            <DatesBox>
              <H>
                {cabin?.name === "Schlossgarten"
                  ? dict("contents.codeBox", lang)
                  : reservation?.property?.code === "SPOT29"
                  ? dict("contents.codeLenzen", lang)
                  : dict("contents.code", lang)}
              </H>
              <KeyCodeP
                dangerouslySetInnerHTML={{ __html: getKeyCodeLabel() }}
              ></KeyCodeP>
            </DatesBox>
          </DatesWrapper>
        </BoxWrapper>

        {daysBeforeArrival <= 1 || reservation.checkInStatus ? (
          <BoxWrapper>
            <DatesWrapper>
              <DatesBox>
                <H>{dict("contents.codeCart", lang)}</H>
                <P>1535</P>
              </DatesBox>
              {AD_BIK_LS_Booked && (
                <DatesBox>
                  <H>{dict("contents.codeBike", lang)}</H>
                  <P>{AD_BIK_LS_Booked.usageCode}</P>
                </DatesBox>
              )}
              {AD_EBIK_LS_Booked && (
                <DatesBox>
                  <H>{dict("contents.codeEBike", lang)}</H>
                  <P>{AD_EBIK_LS_Booked.usageCode}</P>
                </DatesBox>
              )}
              {AD_BIK_ZW_Booked && (
                <DatesBox>
                  <H>{dict("contents.codeBike", lang)}</H>
                  <P>{AD_BIK_ZW_Booked.usageCode}</P>
                </DatesBox>
              )}
              {AD_BB_LS_Booked && (
                <DatesBox>
                  <H>{dict("contents.codeBBQ", lang)}</H>
                  <P>{AD_BB_LS_Booked.usageCode}</P>
                </DatesBox>
              )}
            </DatesWrapper>
          </BoxWrapper>
        ) : null}

        <BoxWrapper>
          <DatesWrapper>
            <DatesBox>
              <H>{dict("contents.arrival", lang)}</H>
              <P>
                {dateArrival} {dict("contents.arrivalFrom", lang)} {arrivalTime}{" "}
                {dict("contents.arrivalClock", lang)}
              </P>
            </DatesBox>
            <DatesBox>
              <H>{dict("contents.departure", lang)}</H>
              <P>
                {dateDeparture} {dict("contents.departureUntil", lang)}{" "}
                {departureTime} {dict("contents.departureClock", lang)}
              </P>
            </DatesBox>
          </DatesWrapper>
        </BoxWrapper>

        <BoxWrapper>
          <InfoBox></InfoBox>
          {!reservation.checkInStatus && (
            <InfoBox>
              <ButtonLink
                caption={dict("checkIn.finishCheckin", lang)}
                to={"/check-in?reservation=" + reservation.id}
                icon={check_box}
              />
            </InfoBox>
          )}
        </BoxWrapper>

        {showHostInfo && reservation?.hubSpotDeal?.host && (
          <HostInfo host={reservation?.hubSpotDeal?.host}></HostInfo>
        )}

        <MaybeShowLateCheckout
          hasUpsellPotentialAfter={hasUpsellPotentialAfter}
          lateCheckOutServiceBooked={lateCheckOutServiceBooked}
          stayLongerStatus={reservation?.upsellStatus?.status}
          lateCheckOutServiceBookable={lateCheckOutServiceBookable}
          lateCheckOutService={lateCheckOutService}
          lang={lang}
          reservation={reservation}
          intent={intent}
        />

        <MaybeShowStayLongerCard
          lateCheckOutServiceBooked={lateCheckOutServiceBooked}
          hasUpsellPotentialAfter={hasUpsellPotentialAfter}
          daysBeforeArrival={daysBeforeArrival}
          lang={lang}
          reservation={reservation}
          intent={intent}
        />

        {cabin?.wbs_value >= 3 ? <FireWarning /> : null}
        <NotifyIssues daysBeforeArrival={daysBeforeArrival} />
      </Item>
    </ContentBox>
  );
}

const MaybeShowStayLongerCard = ({
  hasUpsellPotentialAfter,
  lateCheckOutServiceBooked,
  daysBeforeArrival,
  lang,
  intent,
  reservation,
}) => {
  if (!hasUpsellPotentialAfter || !!lateCheckOutServiceBooked) return;

  const departureDate = dayjs(reservation?.departure);
  // console.log("departureDate", departureDate);
  const isDepartureOnSunday = departureDate.day() === DAYS_OF_THE_WEEK.SUNDAY;
  // console.log("isDepartureOnSunday", isDepartureOnSunday);
  const isOneDayBeforeDeparture = departureDate
    .subtract(1, "day")
    .isSame(now(), "day");
  // console.log("isOneDayBeforeDeparture", isOneDayBeforeDeparture);

  /** Throughout the week we only want to show upsell short before departure, to not "destroy" any slots that are still likely booked
   * The sunday upsell is always possible */
  if (!isDepartureOnSunday && !isOneDayBeforeDeparture) {
    return null;
  }

  const isTooLateToUpsell = now().isSameOrAfter(departureDate.startOf("day"));

  if (isTooLateToUpsell) {
    if (intent === "extend") {
      return (
        <div>
          <Warning>{dict("stayLonger.notAvailableAnymore", lang)}</Warning>
        </div>
      );
    }
    return null;
  }

  return (
    <StayLongerCard
      reservation={reservation}
      daysBeforeArrival={daysBeforeArrival}
      isDepartureOnSunday={isDepartureOnSunday}
    />
  );
};

const MaybeShowLateCheckout = ({
  hasUpsellPotentialAfter,
  stayLongerStatus,
  lateCheckOutServiceBookable,
  lateCheckOutServiceBooked,
  lateCheckOutService,
  lang,
  reservation,
  intent,
}) => {
  // console.log(" SL hasUpsellPotentialAfter", hasUpsellPotentialAfter);
  // console.log(" SL stayLongerStatus", stayLongerStatus);
  // console.log(" SL lateCheckOutServiceBookable", !!lateCheckOutServiceBookable);
  // console.log(" SL lateCheckOutServiceBooked", !!lateCheckOutServiceBooked);
  // console.log(" SL intent", intent);
  // console.log(" SL reservation", reservation);
  // console.log(" SL lateCheckOutService", lateCheckOutService);

  if (
    !hasUpsellPotentialAfter ||
    stayLongerStatus !== "NoRequestExisting" ||
    !lateCheckOutServiceBookable
  ) {
    return null;
  }

  const departureDate = dayjs(reservation?.departure);
  const isDepartureOnSunday = departureDate.day() === DAYS_OF_THE_WEEK.SUNDAY;
  const isOneDayBeforeDeparture = departureDate
    .subtract(1, "day")
    .isSame(now(), "day");

  /** Throughout the week we only want to show upsell short before departure, to not "destroy" any slots that are still likely booked
   * The sunday upsell is always possible */
  if (!isDepartureOnSunday && !isOneDayBeforeDeparture) {
    return null;
  }

  const isTooLateToUpsell = now().isSameOrAfter(departureDate.startOf("day"));

  if (isTooLateToUpsell) {
    if (intent === "extend") {
      return (
        <div>
          <Warning>{dict("lateCheckOut.notAvailableAnymore", lang)}</Warning>
        </div>
      );
    }
    return null;
  }

  if (lateCheckOutServiceBooked) {
    return <InfoBox>{dict("lateCheckOut.booked", lang)}</InfoBox>;
  }

  return (
    <LateCheckOutCard
      // the short daysBeforeArrival ensures that the card is shown also when the arrival is far in the future
      daysBeforeArrival={7}
      lateCheckOutService={lateCheckOutService}
    />
  );
};

const getArrivalTime = ({ lang, arrivalDate, spotCode }) => {
  // for now we return a static departure time for fichtenwald because has an incorrect departure time in apaleo
  // TODO-LUCCA: Change back once fixed in apaleo
  if (spotCode === "SPOT_2") return "15:00";

  if (lang === "de") {
    return arrivalDate.format("HH:mm");
  }

  return arrivalDate.format("h:mm a");
};

const getDepartureTime = ({ lang, departureDate, spotCode }) => {
  // for now we return a static departure time for fichtenwald because has an incorrect departure time in apaleo
  // TODO-LUCCA: Change back once fixed in apaleo
  if (spotCode === "SPOT45") return "11:00";

  if (lang === "de") {
    return departureDate.format("HH:mm");
  }

  return departureDate.format("h:mm a");
};

export default BasicFacts;
