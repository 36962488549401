import { useRef, useState } from "react";

function IFrameWrapper({ locationId, reservationId }) {
  const ref = useRef();
  const [height, setHeight] = useState("0px");
  const onLoad = () => {
    // console.log('onLoad')
    // console.log(ref.current.contentWindow.document.body.scrollHeight)
    // setHeight(ref.current.contentWindow.document.body.scrollHeight + 'px')
  };

  return (
    <iframe
      ref={ref}
      // onLoad={onLoad}
      id="myFrame"
      src={`https://app.revaultapp.co/o/raus/r/89342?location_id=${locationId}&reservation_id=${reservationId}`}
      width="100%"
      // height={height}
      scrolling="yes"
      frameBorder="0"
      style={{
        maxWidth: 640,
        width: "100%",
        height: "calc(100vh - 100px)",
        overflow: "auto",
      }}
    ></iframe>
  );
}

export default IFrameWrapper;
